import {useTranslate} from "hooks/useTranslate";

interface Props {
  plot: { position?: { locationNumber: number, table: number } | null } | undefined | null
}

export const PlotPosition = ( { plot }: Props) => {
  const { t } = useTranslate("plot");

  return <>{plot?.position ? `${t('greenhouse')} ${plot.position.locationNumber}, ${t('table')} ${plot.position.table}` : ''}</>;
}