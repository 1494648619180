import React from 'react';
import './App.css';
import {Layout, Menu} from "antd";
import {Crossings} from "routes/Crossings";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import {ItemType} from "antd/es/menu/hooks/useItems";
import {Crossing} from "routes/Crossing";
import {ImportExport} from "routes/ImportExport";
import {useEventUpdated} from "hooks/useEventUpdated";
import {Plots} from "routes/Plots";
import {Reserved} from "routes/Reserved";
import {Plot} from "routes/Plot";
import {useGetRole} from "hooks/useGetRole";
import {UserRole} from "generated/graphql";
import {Programs} from "routes/Programs";
import {useTranslate} from 'hooks/useTranslate';
import {Stock} from "routes/Stock";
import {environment} from "env";
import {EventLog} from "routes/EventLog";
import {Users} from "routes/Users";

const { Header, Content, Footer } = Layout;

const lngs: { [key: string]: string } = {
  en: 'English',
  nl: 'Nederlands',
  es: 'Español'
};

const navItems = [
  {
    key: "/crossings",
    title: 'crossings',
    element: <Crossings />
  },
  {
    key: "/plots",
    title: 'plots',
    element: <Plots />
  },
  {
    key: "/stock",
    title: 'stock',
    element: <Stock />
  },
  {
    key: "/reserved",
    title: 'pick_up_list',
    element: <Reserved />
  },
  {
    key: "/programs",
    title: 'programs',
    element: <Programs />
  },
  {
    key: "/log",
    title: 'log',
    element: <EventLog />
  },
  {
    key: "/users",
    title: 'users',
    role: UserRole.Admin,
    element: <Users />
  },
  {
    key: "/importexport",
    title: 'import_export',
    role: UserRole.Admin,
    element: <ImportExport />
  }];

function App() {
  const { instance, accounts } = useMsal();
  useEventUpdated();

  const { t, i18n } = useTranslate('app');

  const location = useLocation();
  const role = useGetRole();

  const menuItems: ItemType[] = [
    ...navItems
      .filter(i => !i.role || i.role === role)
      .map(n => ({ key: n.key, label: <Link to={n.key}>{t(n.title)}</Link> })),
    {
      key: "language",
      label: t('language'),
      children: Object.keys(lngs).map(l => ({
        key: l,
        label: lngs[l],
        onClick: () => i18n.changeLanguage(l)
      })),
      style: {
        marginLeft: 'auto'
      }
    },
    {
      key: "user",
      label: accounts[0]?.name,
      children: [
        {
          key: "logout",
          label: "Logout",
          onClick: () => instance.logout()
        }
      ]
    }
  ];

  return (
    <Layout className={environment !== "production" ? "test" : ""}>
      <Header style={{ background: "white", position: 'sticky', top: 0, zIndex: 1, width: '100%' }}>
        <div className="app-title">{t('title')}{ environment !== "production" && " (test)" }</div>
        <Menu mode="horizontal"
              theme="light"
              items={menuItems}
              selectedKeys={[location.pathname]}
              style={{paddingLeft: '20px'}} />
      </Header>

      <Content style={{ padding: '50px 50px' }}>
        <Routes>
          { navItems
            .map(i => <Route key={i.key} path={i.key} element={(!i.role || i.role === role) ? i.element : <div>Access denied</div>}  />) }
          <Route path="/crossings/:crossingCode" element={<Crossing />} />
          <Route path="/plots/:plotCode" element={<Plot />} />
          <Route path="/" element={<Navigate to="/crossings" />} />
        </Routes>
      </Content>
      <Footer></Footer>
    </Layout>
  );
}

export default App;
