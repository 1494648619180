import {useQuery} from "@apollo/client";
import {CrossingInfoFragment, GetCrossingsDocument, GetCrossingsQuery} from "generated/graphql";
import {Input} from "antd";
import {Link} from "react-router-dom";
import {useMemo, useState} from "react";
import {plotSort, positionSort} from "helpers";
import {PlotLabel} from "components/PlotLabel";
import { useTranslate } from "hooks/useTranslate";
import {PlotPosition} from "components/PlotPosition";
import {Column} from "components/Table/Column";
import {DataTable} from "components/Table/DataTable";

export const Crossings = () => {
  const { data, loading } = useQuery<GetCrossingsQuery>(GetCrossingsDocument);
  const [query, setQuery] = useState("");
  const { t } = useTranslate('crossing');

  const q = useMemo(() => query.toLowerCase(), [query]);
  const filtered = useMemo(() => data?.crossings.filter(c => c.code.toLowerCase().includes(q)
    || c.mother?.code.toLowerCase().includes(q) || c.father?.code.toLowerCase().includes(q)),
    [q, data]);

  const columns: Column<CrossingInfoFragment>[] = useMemo(() => [
    {
      value: a => a.site,
      key: 'country',
      filter: true
    },
    {
      value: a => a.program,
      key: 'program',
      filter: true
    },
    {
      value: a => a.code,
      key: 'crossing_plot',
      render: r => <Link to={`/crossings/${r.code}`}>{r.code}</Link>
    },
    {
      key: 'mother_plot',
      value: a => a.mother?.code,
      render: a => <PlotLabel plot={a.mother} />,
      sorter: (a, b) => plotSort(a.mother, b.mother)
    },
    {
      value: a => `${a.mother?.position?.locationNumber}-${a.mother?.position?.table}`,
      key: 'mother_position',
      filter: true,
      render: a => <PlotPosition plot={a.mother} />,
      sorter: (a, b) => positionSort(a.mother?.position, b.mother?.position)
    },
    {
      key: 'father_plot',
      value: a => a.father?.code,
      render: a => <PlotLabel plot={a.father} />,
      sorter: (a, b) => plotSort(a.father, b.father)
    },
    {
      value: a => `${a.father?.position?.locationNumber}-${a.father?.position?.table}`,
      key: 'father_position',
      filter: true,
      render: (a) => <PlotPosition plot={a.father} />,
      sorter: (a, b) => positionSort(a.father?.position, b.father?.position)
    },
    {
      key: 'num_desired',
      value: a => a.count
    },
    {
      value: a => a.result,
      key: 'num_harvested',
    }
  ], []);

  return <>
    { loading && <div><i>{t('loading')}</i></div> }
    { filtered && <>
        <div style={{marginBottom: 15, maxWidth: 400}}>
          <Input size="large" placeholder= {t('search_code') ?? undefined} onInput={e => setQuery(e.currentTarget.value)} value={query} />
        </div>
        <DataTable source={filtered} columns={columns} translatePrefix="crossing" />
    </>}
  </>
}