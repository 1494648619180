import {useMutation, useQuery} from "@apollo/client";
import {GetUsersDocument, UpdateUserDocument, UserFragment, UserRole} from "generated/graphql";
import {Column} from "components/Table/Column";
import {DataTable} from "components/Table/DataTable";
import {Button, Modal, Radio} from "antd";
import {useState} from "react";
import {useTranslate} from "hooks/useTranslate";

export const Users = () => {
  const { data, refetch } = useQuery(GetUsersDocument);
  const [editUser, setEditUser] = useState<UserFragment | null>(null);
  const [role, setRole] = useState<UserRole>(UserRole.Normal);

  const { t } = useTranslate('users');
  const [updateUser] = useMutation(UpdateUserDocument);

  const columns: Column<UserFragment>[] = [
    {
      value: t => t.name,
      key: 'name'
    },
    {
      value: t => t.email,
      key: 'email'
    },
    {
      value: t => t.role,
      key: 'role',
      render: u => <>{u.role}&nbsp;
        <Button type="link"
                onClick={() => { setEditUser(u); setRole(u.role); }}>
          ({t('edit')})
        </Button>
      </>
    }
  ];

  const editRole = async () => {
    if (!editUser) return;
    await updateUser({
      variables: { userId: editUser.id, role: role }
    });
    await refetch();
    setEditUser(null);
  };

  return <>
    { data && <DataTable source={data.users}
                         columns={columns}
                         translatePrefix="users" /> }
    <Modal title={t('change_role')}
           open={editUser !== null}
           onOk={editRole}
           onCancel={() => setEditUser(null)}>
      <div style={{ marginBottom: 10 }}>{t('choose_role')}:</div>
      <Radio.Group options={[UserRole.Normal, UserRole.Admin]}
                   value={role}
                   onChange={e => setRole(e.target.value)}
      />
    </Modal>
  </>
}