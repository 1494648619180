import {useQuery} from "@apollo/client";
import {
  EventUpdatedByCrossingDocument,
  EventUpdatedByPlotDocument,
  GetEventsDocument,
  GetEventsQuery
} from "generated/graphql";
import {useEffect} from "react";

interface Props {
  plotId?: number;
  crossingId?: number;
}

export const useGetEvents = ({ plotId, crossingId }: Props) => {
  const ready = plotId || crossingId;

  const { data, subscribeToMore }  = useQuery<GetEventsQuery>(GetEventsDocument, {
    variables: { plotId, crossingId },
    skip: !ready
  });

  useEffect(() => {
    if (crossingId) {
      subscribeToMore({
        document: EventUpdatedByCrossingDocument,
        variables: {crossingId},
        updateQuery: (prev, {subscriptionData}) => {
          if (!subscriptionData) return prev;
          return {
            __typename: "Query",
            events: [subscriptionData.data.eventUpdatedByCrossing, ...prev.events]
          };
        }
      });
    }
    if (plotId) {
      subscribeToMore({
        document: EventUpdatedByPlotDocument,
        variables: {plotId},
        updateQuery: (prev, {subscriptionData}) => {
          if (!subscriptionData) return prev;
          return {
            __typename: "Query",
            events: [subscriptionData.data.eventUpdatedByPlot, ...prev.events]
          };
        }
      });
    }
  }, [subscribeToMore, crossingId, plotId]);

  return data?.events;
}