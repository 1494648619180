import {Column} from "components/Table/Column";
import {Button, Table} from "antd";
import {utils, writeFile} from "xlsx";
import {useMemo} from "react";
import {ColumnType} from "antd/es/table";
import {useTranslate} from "hooks/useTranslate";

interface Props<T> {
  source: T[];
  columns: Column<T>[];
  translatePrefix?: string;
  summary?: () => JSX.Element;
  exportPrefix?: string;
  extraExportHeader?: string[]
}


function generateExport<T>(source: T[], cols: Column<T>[], fileName: string, extraHeader?: string[]) {
  const data = source.map(t => cols.map(c => c.value(t)));
  const headers = cols.map(c => c.key);
  const ws = utils.aoa_to_sheet(extraHeader ? [extraHeader, headers, ...data] : [headers, ...data]);
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Export");
  writeFile(wb, fileName);
}

export function DataTable<T extends object>({ source, columns, translatePrefix, summary, exportPrefix = "", extraExportHeader }: Props<T>) {
  const { t } = useTranslate(translatePrefix);

  const mappedColumns = useMemo(() => columns.filter(c => !c.exportOnly).map<ColumnType<T>>(c => ({
    title: c.header ?? t(c.key),
    key: c.key,
    filters: c.filter ? Array.from(new Set(source.map(a => c.value(a)))).map(p => ({ text: p, value: p })) : undefined,
    onFilter: c.filter ? ((f,a) => c.value(a) === f) : undefined,
    render: c.render ?? (a => c.value(a)?.toString()),
    sorter: c.sorter ?? ((a,b) => typeof(c.value(a)) === "number" ? c.value(a) - c.value(b)
      :  c.value(a)?.toString().localeCompare(c.value(b)?.toString()))
  })), [columns, source, t]);

  return <>
    <div style={{textAlign: "right", marginBottom: 20}}>
      <Button onClick={() => generateExport(source,columns, `${exportPrefix}${translatePrefix}_export.xlsx`,extraExportHeader)}>
        Export data
      </Button>
    </div>
    <Table dataSource={source} rowKey="id" sticky={{ offsetHeader: 64 }}
           columns={mappedColumns} pagination={false}
           summary={summary}
    />
  </>
}