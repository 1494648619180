import {useAddEvent} from "hooks/useAddEvent";
import {useState} from "react";
import {EventType} from "generated/graphql";
import {Button, InputNumber, Modal} from "antd";
import {useTranslate} from "hooks/useTranslate";

interface Props {
  crossing: { count: number, id: number };
}

export const CountEdit = ({ crossing }: Props) => {
  const [addEvent] = useAddEvent();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount] = useState(crossing.count ?? 0);

  const { t } = useTranslate("edit-dialog");

  async function editCount() {
    if (!crossing) return;
    setIsModalOpen(false);
    await addEvent({
      variables: {
        crossingId: crossing.id,
        type: EventType.EditCount,
        count: count - crossing.count
      }
    });
  }

  return <>
    <Button type="link" onClick={() => setIsModalOpen(true)}>{t('edit')}</Button>
    <Modal title={t('title')}
           open={isModalOpen}
           onOk={editCount}
           onCancel={() => setIsModalOpen(false)}>
      <div>{t('number')}</div>
      <div>
        <InputNumber defaultValue={crossing.count} min={0} max={1000}
                     onChange={n => setCount(n ?? 0)}/>
      </div>
    </Modal>
  </>
}