import {Button, Table} from "antd";
import {useMutation, useQuery} from "@apollo/client";
import {GenerateExportDocument, GetExportDocument, GetExportsDocument, MutationStatus} from "generated/graphql";
import {ColumnType} from "antd/es/table";
import {DateTime} from "luxon";
import {useState} from "react";
import {utils, writeFile} from "xlsx";

type Export = {
  id: number
  date: string
}

export const ExportTable = () => {
  const { data, refetch } = useQuery(GetExportsDocument);
  const [generate, result] = useMutation(GenerateExportDocument);
  const [isGenerating, setIsGenerating] = useState(false);

  const { refetch: fetchExport } = useQuery(GetExportDocument, { skip: true });

  const columns: ColumnType<Export>[] = [
    {
      key: 'date',
      title: 'Date',
      render: (_, a) => DateTime.fromISO(a.date).toLocaleString(DateTime.DATETIME_MED)
    },
    {
      key: 'download',
      title: 'Download',
      render: (_, a) => <Button type="link" onClick={async () => {
        const res = await fetchExport({ id: a.id });
        const rows = res.data.export.rows;
        const ws = utils.json_to_sheet(rows.map(r => ({
          BreedingOperation: r.breedingOperation,
          BreedingOperationType: r.breedingOperationType,
          Plot: r.plot,
          ObservationDate: DateTime.fromISO(r.date).toISODate(),
          'Kruisen #vr': r.count,
          Feedback: '',
          Done: ''
        })));
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Export");
        writeFile(wb, `${DateTime.fromISO(a.date).toISODate()}_ebrida_export.xlsx`);
      }}>{DateTime.fromISO(a.date).toISODate()}_ebrida_export.xlsx</Button>
    }
  ]

  return <div>
    <Button type="primary" loading={isGenerating} onClick={async () => {
      setIsGenerating(true);
      try {
        await generate();
      } catch {
        alert('An error has occurred generating export. Diagnostic information has been saved');
      }
      await refetch();
      setIsGenerating(false);
    }}>Generate new export</Button>
    { result.data?.generateExport.status === MutationStatus.NotFound &&
        <p style={{color: 'darkred'}}>No changes since last export</p>
    }

    <Table dataSource={data?.exports} columns={columns}
           rowKey="id" pagination={false}
           style={{marginTop: 30, maxWidth: 800}} />
  </div>
}