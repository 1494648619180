import {Link, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {CrossingInfoFragment, EventType, GetPlotDocument, PlotType, UserRole} from "generated/graphql";
import {Col, Descriptions, Row, Table} from "antd";
import {CountEdit} from "components/CountEdit";
import {ColumnType} from "antd/es/table";
import {EventsTable} from "components/EventsTable";
import {useGetEvents} from "hooks/useGetEvents";
import {useGetRole} from "hooks/useGetRole";
import {PlotLabel} from "components/PlotLabel";
import { useTranslate } from "hooks/useTranslate";

const Item = Descriptions.Item;

export const Plot = () => {
  const {plotCode} = useParams<{ plotCode: string }>();
  if (!plotCode) throw new Error("Missing plot code");

  const {data, loading} = useQuery(GetPlotDocument, {
    variables: {
      type: plotCode[0] === "F" ? PlotType.Father : PlotType.Mother,
      plotCode: plotCode.slice(2)
    }
  });

  const role = useGetRole();
  const plot = data?.plotByCode;
  const selfing = plot?.crossingsMother.filter(t => !t.father)[0];
  const { t } = useTranslate('plot');

  const columns: ColumnType<CrossingInfoFragment>[] = [
    {
      title: t('code'),
      render: (_, a) => <Link to={`/crossings/${a.code}`}>{a.code}</Link>,
      key: 'code'
    },
    {
      title: t('num_desired'),
      dataIndex: 'count',
      key: 'count'
    }
  ];

  const events = useGetEvents({ plotId: plot?.id });

  return <div>
    {loading && <div><i>{t('loading')}</i></div>}
    {plot &&
        <>
            <Row gutter={32}>
                <Col span={12}>
                    <Descriptions title="Plot" bordered column={1}>
                        <Item label={plot.type === PlotType.Father ? t('father_plot') : t('mother_plot')}> <PlotLabel plot={plot} /> </Item>
                        <Item label={t('position')}>
                          {plot.position && <>{t('greenhouse')} {plot.position.locationNumber}, {t('table')} {plot.position.table}</>}
                        </Item>
                      { (plot.crossingsFather.filter(f => f.mother).length > 0 || plot.spareCount) &&
                          <Item label={t('spares')}>{plot.spareCount}</Item>}
                      {selfing && <>
                          <Item label="Inbredplot">{selfing.code}</Item>
                          <Item label="Aantal inbreds gewenst">{selfing.count} {role === UserRole.Admin && <CountEdit crossing={selfing}/>}</Item>
                      </>}
                        <Item label={t('available')}>{plot.available}</Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row gutter={32}>
                <Col span={12}>
                    <h3 style={{marginTop: 40}}>{t('crossings_father')}</h3>
                    <Table dataSource={plot.crossingsFather} rowKey="id" columns={columns} pagination={false} />
                </Col>
                <Col span={12}>
                    <h3 style={{marginTop: 40}}>{t('crossings_mother')}</h3>
                    <Table dataSource={plot.crossingsMother.filter(c => c.father)} rowKey="id" columns={columns} pagination={false} />
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                  {events && <EventsTable events={events.filter(e => e.type !== EventType.Import || e.plotId === plot.id)} allowUndo={role === UserRole.Admin} /> }
                </Col>
            </Row>
        </>
    }
  </div>
}