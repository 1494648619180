import {useQuery} from "@apollo/client";
import {GetPlotsDocument, GetPlotsQuery, PlotFragment, PlotType} from "generated/graphql";
import {useTranslate} from "hooks/useTranslate";
import {DateTime} from "luxon";
import {useMemo} from "react";
import {Column} from "components/Table/Column";
import {getBaseColumns} from "routes/Plots/helpers";
import {DataTable} from "components/Table/DataTable";

export const Plots = () => {
  const { data, loading } = useQuery<GetPlotsQuery>(GetPlotsDocument);
  const { t } = useTranslate('plot');

  const cols: Column<PlotFragment>[] = useMemo(() => [
    ...getBaseColumns(t),
    {
      key: 'on_hold',
      value: a => a.dates.onHold && DateTime.fromISO(a.dates.onHold).toLocaleString(DateTime.DATE_MED)
    },
    {
      key: 'done',
      value: a => a.dates.done && DateTime.fromISO(a.dates.done).toLocaleString(DateTime.DATE_MED)
    },
    {
      key: 'removed',
      value: a => a.dates.removed && DateTime.fromISO(a.dates.removed).toLocaleString(DateTime.DATE_MED)
    },
    {
      key: 'type',
      value: a => a.type === PlotType.Father ? t('father') : t('mother'),
      filter: true
    },
  ], [t]);

  return <>
    { loading && <div>{t('loading')}</div> }
    { data && <>
      <DataTable source={data.plots} columns={cols} translatePrefix="plot" />
    </> }
  </>
}