export const getSortCode = (plotCode: string) =>
  plotCode.replace('A-', '-');

export const plotSort = (plot1: { code: string } | undefined | null,
                         plot2: { code:string } | undefined | null) =>
  getSortCode(plot1?.code ?? "").localeCompare(getSortCode(plot2?.code ?? ""));

type sortPosition = null | undefined | { locationNumber: number, table: number };

export const positionSort = (pos1: sortPosition, pos2: sortPosition) =>
  !pos1 || !pos2 ? -1e10 : pos1.locationNumber * 100 + pos1.table - pos2.locationNumber * 100 - pos2.table