import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {AuthProvider} from "providers/AuthProvider";
import {BrowserRouter} from "react-router-dom";
import {BrowserTracing} from "@sentry/tracing";
import {init} from "@sentry/react";
import './i18n/i18n';
import {environment, sentryDsn} from "env";

init({
  dsn: sentryDsn,
  integrations: [new BrowserTracing()],
  environment: environment,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);

