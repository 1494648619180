import {useSubscription} from "@apollo/client";
import {
  EventType,
  EventUpdatedDocument,
  EventUpdatedSubscription,
  EventUpdatedSubscriptionVariables
} from "generated/graphql";

export const useEventUpdated = () => useSubscription<EventUpdatedSubscription, EventUpdatedSubscriptionVariables>(EventUpdatedDocument, {
  onData: ({ data, client }) => {
    if (data.data?.eventUpdated.id) {
      const cache = client.cache;
      const updated = data.data.eventUpdated;
      if (updated.type === EventType.EditCount) {
        cache.modify({
          id: cache.identify({
            __typename: 'Crossing',
            id: updated.crossingId
          }),
          fields: {
            count(existing) {
              return existing + updated.delta;
            }
          }
        });
        if (updated.plotId) {
          cache.modify({
            id: cache.identify({
              __typename: 'Plot',
              id: updated.plotId
            }),
            fields: {
              counts(existing) {
                return {
                  ...existing,
                  toCollect: existing.toCollect + updated.delta
                };
              }
            }
          });
        }
      }
      else {
        cache.modify({
          id: cache.identify({
            __typename: 'Plot',
            id: updated.plotId
          }),
          fields: {
            counts(existing) {
              if (updated.type === EventType.Selfing) {
                return {
                  ...existing,
                  toSelf: existing.toSelf - updated.delta
                };
              } else if (updated.type === EventType.EpIn) {
                return {
                  ...existing,
                  toCollect: existing.toCollect - updated.delta,
                  collected: existing.collected + updated.delta
                };
              }
              return existing;
            }
          }
        });
      }
    }
  }
});