import {PropsWithChildren, useMemo} from "react";
import {ApolloClient, ApolloProvider, concat, createHttpLink, InMemoryCache, split} from "@apollo/client";
import {useMsal} from "@azure/msal-react";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {createClient} from "graphql-ws";
import {getMainDefinition} from "@apollo/client/utilities";
import {graphqlUri, scope} from "env";
import {setContext} from "@apollo/client/link/context";
import {InteractionRequiredAuthError} from "@azure/msal-browser";

const httpLink = createHttpLink({ uri: graphqlUri });
const wsLink = new GraphQLWsLink(createClient({
  url: graphqlUri.replace('http', 'ws')
}));

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

export const GraphqlProvider = ({children}: PropsWithChildren<{}>) => {
  const { instance } = useMsal();

  const client = useMemo(() => {
    const authMiddleware = setContext(async (_, {headers}) => {
      const account = instance.getAllAccounts()[0];
      if (!account) {
        return headers;
      }
      const request = {
        scopes: [scope],
        account: account
      };
      const res = await instance
        .acquireTokenSilent(request)
        .catch(async (error) => {
          if (error instanceof InteractionRequiredAuthError) {
            // fallback to interaction when silent call fails
            return await instance.acquireTokenRedirect(request);
          }
        });
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${res?.accessToken}`
        }
      };
    });

    const link = concat(authMiddleware, splitLink);
    return new ApolloClient({
      link,
      cache: new InMemoryCache()
    });
  }, [instance]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}