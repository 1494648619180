import {ColumnType} from "antd/es/table";
import {EventFragment, EventType} from "generated/graphql";
import {DateTime} from "luxon";
import {Button, Table} from "antd";
import {useUpdateEvent} from "hooks/useUpdateEvent";
import {useState} from "react";
import {useTranslate} from "hooks/useTranslate";

interface Props {
  events: EventFragment[]
  allowUndo: boolean;
}

export const EventsTable = ({ events, allowUndo }: Props) => {
  const [updateEvent] = useUpdateEvent();
  const [working, setWorking] = useState(false);
  const { t } = useTranslate('events-table');

  async function undo(ev: EventFragment) {
    setWorking(true);
    await updateEvent({
      variables: { eventId: ev.id, delta: ev.count > 0 ? -1 : 1 }
    });
    setWorking(false);
  }

  const columns: ColumnType<EventFragment>[] = [
    {
      title: t('date'),
      render: (_, ev) => DateTime.fromISO(ev.date).toLocaleString(DateTime.DATETIME_MED),
      key: 'date'
    },
    {
      title: t('user'),
      dataIndex: 'user',
      key: 'user'
    },
    {
      title: t('event'),
      key: 'event',
      render: (_, ev) => {
        switch (ev.type){
          case EventType.EditCount:
            return <>{t('num_desired')} {ev.count > 0 ? t('increased'): t('decreased')} (<b>{ev.count > 0 ? "+" : ""}{ev.count}</b>)</>;
          case EventType.EditSpares:
            return <>{t('num_spares')} {ev.count > 0 ? t('increased'): t('decreased')} (<b>{ev.count > 0 ? "+" : ""}{ev.count}</b>)</>;
          case EventType.EditPriority:
            return <>{t('priority')} {ev.count > 0 ? t('increased'): t('decreased')} (<b>{ev.count > 0 ? "+" : ""}{ev.count}</b>)</>;
          case EventType.EditPosition:
            return <>{t('edit_position')}</>;
          case EventType.EditData:
            return <>{t('edit_data')}</>;
          case EventType.Unreserve:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('unreserved1') : t('unreserved')} {t('unreserved')}</>;
          case EventType.Remove:
            return <>{t('remove')}</>;
          case EventType.Unremove:
            return <>{t('unremove')}</>;
          case EventType.Selfing:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('flower') : t('flowers')} {t('encased')}</>
          case EventType.EpIn:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('tube') : t('tubes')} {t('scanned')}</>;
          case EventType.EpReturn:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('tube') : t('tubes')} {t('returned')}</>;
          case EventType.EpOut:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('tube') : t('tubes')} {t('list')}</>;
          case EventType.Emasculate:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('flower') : t('flowers')} {t('emasculated')}</>
          case EventType.Reserve:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('tube') : t('tubes')} {ev.plot?.code} {t('reserved')}</>;
          case EventType.Crossing:
            return <><b>{ev.count}</b> {ev.count === 1 ? t('flower') : t('flowers')} {t('crossed')}</>;
          case EventType.Collect25:
            return <><b>{ev.count}</b> {t('quarter')} {ev.count === 1 ? t('fruit') : t('fruits')} {t('harvested')}</>;
          case EventType.Collect50:
            return <><b>{ev.count}</b> {t('half')} {ev.count === 1 ?  t('fruit') : t('fruits')} {t('harvested')}</>;
          case EventType.Collect75:
            return <><b>{ev.count}</b> {t('three_quarter')} {ev.count === 1 ?  t('fruit') : t('fruits')} {t('harvested')}</>;
          case EventType.Collect100:
            return <><b>{ev.count}</b> {t('entire')} {ev.count === 1 ?  t('fruit') : t('fruits')} {t('harvested')}</>;
          case EventType.Fail:
            return <><b>{ev.count}</b> {ev.count === 1 ?  t('fruit') : t('fruits')} {t('failed')}</>;
          case EventType.Lost25:
            return <><b>{ev.count}</b> {t('quarter')} {ev.count === 1 ?  t('fruit') : t('fruits')} {t('lost')}</>;
          case EventType.Import:
            return <>{t('data_imported')}</>
          case EventType.EditProgram:
            return <>{t('edit_program')}</>
        }
      }
    },
    {
      title: '',
      key: 'action',
      render: (_, ev) => {
        if (!allowUndo || DateTime.now().diff(DateTime.fromISO(ev.date), 'hours').hours > 1 || ev.count === 0) return "";
        return <Button onClick={() => undo(ev)} type="link" disabled={working}>{t('undo')}</Button>
      }
    }
  ];

  return <>
    <h3 style={{marginTop: 40}}>{t('history')}</h3>
    <Table dataSource={events} columns={columns} rowKey="id" pagination={false} />
  </>
}