import yaml from "yaml";
import * as i18next from 'i18next';

export default class YamlBackend implements i18next.BackendModule {
    init(services: any, backendOptions: any, i18nextOptions: any) {

    }

    process(lang: string, obj: any): any {
        if (typeof Object.values(obj)[0] == "string") {
            return obj[lang];
        }
        const entries = Object.entries(obj).map(([k, v]) => {
            return [k, this.process(lang, v)];
        });
        return Object.fromEntries(entries);
    }

    read(language: string, namespace: string, callback: any) {
        fetch('/translations.yaml', { cache: 'no-cache' })
          .then(r => r.text())
          .then(r => {
              const res = yaml.parse(r);
              callback(null, this.process(language, res));
          });
        /* if method fails/returns an error, call this: */
        /* callback(truthyValue, null); */
    }

    static type: 'backend' = 'backend';
    type: 'backend' = 'backend';
}