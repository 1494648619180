import {Link, useParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {EventType, GetCrossingDocument, GetCrossingQuery, UserRole} from "generated/graphql";
import {Col, Descriptions, QRCode, Row} from "antd";
import {EventsTable} from "components/EventsTable";
import {useGetEvents} from "hooks/useGetEvents";
import {useGetRole} from "hooks/useGetRole";
import {useTranslate} from "hooks/useTranslate";
import {PlotLabel} from "components/PlotLabel";

const Item = Descriptions.Item;

export const Crossing = () => {
  const {crossingCode} = useParams<{ crossingCode: string }>();
  if (!crossingCode) throw new Error("Missing crossing code");

  const {data, loading} = useQuery<GetCrossingQuery>(GetCrossingDocument, {
    variables: {crossingCode}
  })
  const crossing = data?.crossing;
  const role = useGetRole();

  const { t } = useTranslate('crossing');

  const events = useGetEvents({ crossingId: crossing?.id });

  return <div>
    {loading && <div><i>{t('loading')}</i></div>}
    {crossing &&
        <>
            <Row gutter={32}>
                <Col span={12}>
                    <Descriptions title={t('title')} bordered column={1}>
                        <Item label={t('program')}>{crossing.program} </Item>
                        <Item label={t('plot')}>{crossing.code} </Item>
                        <Item label={t('num_desired')}> {crossing.count} </Item>
                        <Item label={t('priority')}>{crossing.priority}</Item>
                        <Item label={t('harvested')}>{crossing.result} {crossing.failed > 0 && <> ({crossing.failed} {t('failed')})</>}</Item>
                    </Descriptions>
                </Col>
                <Col span={2} />
                <Col span={3} className="qr-column">
                  <h3>{t('title')}</h3>
                  <QRCode value={crossing.code} />
                </Col>
                { crossing.mother && <Col span={3} className="qr-column">
                    <h3>{t('mother')}</h3>
                    <QRCode value={crossing.mother?.code} />
                </Col> }
                { crossing.father && <Col span={3} className="qr-column">
                    <h3>{t('father')}</h3>
                    <QRCode value={crossing.father?.code} />
                </Col> }
            </Row> 
            <Row gutter={32} style={{marginTop: 40}}>
                <Col span={12}>
                    <Descriptions title={t('mother')} bordered column={1}>
                        <Item label={t('plot')}><Link to={`/plots/${crossing.mother?.type.toString()[0]}-${crossing.mother?.code}`}><PlotLabel plot={crossing.mother} /></Link></Item>
                        <Item label={t('position')}>
                          {crossing.mother?.position && <>{t('greenhouse')} {crossing.mother?.position.locationNumber},&nbsp;
                              {t('table')} {crossing.mother?.position.table}</>}
                        </Item>
                        <Item label={t('emasculated')}>
                          {crossing.mother?.counts.available}
                        </Item>
                    </Descriptions>
                </Col>
                <Col span={12}>
                  {crossing.father &&
                      <Descriptions title={t('father')} bordered column={1}>
                          <Item label={t('plot')}><Link to={`/plots/${crossing.father.type.toString()[0]}-${crossing.father.code}`}><PlotLabel plot={crossing.father} /></Link></Item>
                        {crossing.father.position && <Item label={t('position')}>
                        {t('greenhouse')} {crossing.father.position.locationNumber}, {t('table')} {crossing.father.position.table}
                        </Item>}
                          <Item
                              label={t('available')}> {crossing.father.counts.available} {crossing.father.counts.toCollect > 0 && <>({crossing.father.counts.toCollect} {t('still_needed')})</>}
                          </Item>
                      </Descriptions>
                  }
                </Col>
            </Row>
          <Row>
            <Col span={12}>
            {events && <EventsTable events={events.filter(e => e.type !== EventType.Import || e.crossingId === crossing.id)} allowUndo={role === UserRole.Admin} /> }
            </Col>
          </Row>
        </>}
  </div>
}