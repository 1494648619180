import {CellObject, read, utils, WorkBook} from 'xlsx';
import {useState} from "react";
import {useMutation} from "@apollo/client";
import {
  ImportSourceDataDocument,
  ImportSourceDataRowInput
} from "generated/graphql";
import { useTranslate } from 'hooks/useTranslate';
import {Button, Upload} from "antd";
import {ExportTable} from "routes/ImportExport/ExportTable";

export const ImportExport = () => {
  const [isImporting, setIsImporting] = useState(false);
  const [error, setError] = useState('');
  const [importSourceData, result]  = useMutation(ImportSourceDataDocument);
  const resultData = result.data?.importSourceData;

  const { t } = useTranslate('import_export');

  const loadSheet = (book: WorkBook, expected: string[]) => {
    const sheet = book.Sheets[book.SheetNames[0]];
    if (sheet.length < 1) {
      setError(`No data found in ${book.SheetNames[0]}`);
      return undefined;
    }
    const keys = sheet[0].map((z: CellObject) => z.v);
    console.log(keys);
    const missing = expected.filter(n => !keys.includes(n));
    if (missing.length > 0) {
      setError(`Missing column(s) in ${book.SheetNames[0]}: ${missing.join(', ')}`);
    }
    return missing.length > 0 ? undefined : utils.sheet_to_json(sheet);
  }

  async function processFile(file: File) {
    if (!file) { return }
    setIsImporting(true);
    setError('');
    const book = read(await file.arrayBuffer(), { dense: true, cellDates: true });

    const res = loadSheet(book, ['Plot', 'Program', 'Plot mother', 'Plot father',
      'Position mother', 'Position father', 'Group', 'Spares father', 'Flowers planned',
      'BreedingOperation', 'BreedingOperationType', 'Country', 'Father out', 'Mother out',
      'Priority']);
    console.log(res);
    if (!res) {
      setIsImporting(false);
      return;
    }

    const inputCrossings = res
      .filter((c: any) => c['Plot'])
      .map<ImportSourceDataRowInput>((f: any) => ({
      code: f['Plot'],
      count: f['Flowers planned'],
      fatherPlot: f['Plot father'],
      motherPlot: f['Plot mother'],
      fatherSpares: f['Spares father'],
      fatherPosition: f['Position father'],
      motherPosition: f['Position mother'],
      priority: f['Priority'],
      group: f['Group']?.toString(),
      program: f['Program'],
      breedingOperation: f['BreedingOperation'],
      breedingOperationType: f['BreedingOperationType'],
      fatherRemoved: f['Father out'],
      motherRemoved: f['Mother out'],
      site: f['Country']
    })).filter(f => f.motherPosition);

    try {
      await importSourceData({variables: {input: inputCrossings}});
    } catch {
      console.log('error uploading data');
    }
    setIsImporting(false)
  }

  return <div>
    <h3>Import data from Excel</h3>
    <Upload accept=".xlsx" beforeUpload={async f => { await processFile(f); return false; }} maxCount={1}>
      <Button type="primary" loading={isImporting}>
        {t('upload')}
      </Button>
    </Upload>

    {resultData?.error && <p style={{color: "darkred"}}>{t('error')}: {resultData.error}</p>}
    {error && <p style={{color: "darkred"}}>{error}</p>}
    {result.error && <p style={{color: "darkred"}}>Error uploading data: {result.error.message}</p>}
    {resultData && !resultData.error && <p>{resultData.rowsAdded} rows added, {resultData.fieldsChanged} fields edited</p> }

    <h3 style={{marginTop: 50}}>Export data for E-Brida</h3>
    <ExportTable />
  </div>
}