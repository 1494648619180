import {useQuery} from "@apollo/client";
import {EventType, GetStockDocument, PlotWithEventTotalsFragment} from "generated/graphql";
import {Column} from "components/Table/Column";
import {useMemo} from "react";
import {getBaseColumns} from "routes/Plots/helpers";
import {DataTable} from "components/Table/DataTable";
import {useTranslate} from "hooks/useTranslate";

const getEventTotal = (totals: { type: EventType, total: number }[], type: EventType) =>
  totals.filter(t => t.type === type)[0]?.total;

export const Stock = () => {
  const { data, loading } = useQuery(GetStockDocument);
  const { t } = useTranslate('plot');

  const cols = useMemo<Column<PlotWithEventTotalsFragment>[]>(() => [
    ...getBaseColumns(t),
    {
      key: 'needed',
      value: a => a.counts.toCollect
    },
    {
      key: 'ep_in',
      value: a => getEventTotal(a.eventTotals, EventType.EpIn)
    },
    {
      key: 'used',
      value: a => (getEventTotal(a.eventTotals, EventType.Reserve) ?? 0)
        - (getEventTotal(a.eventTotals, EventType.Unreserve) ?? 0)
    },
    {
      key: 'ep_return',
      value: a => getEventTotal(a.eventTotals, EventType.EpReturn)
    },
    {
      key: 'ep_out',
      value: a => getEventTotal(a.eventTotals, EventType.EpOut)
    },
    {
      key: 'available',
      value: a => a.available
    },
    {
      key: 'reserved',
      value: a => a.reserved
    }
  ], [t]);

  return <>
    { loading && <div>{t('loading')}</div> }
    { data && <>
        <DataTable source={data.plots} columns={cols} translatePrefix="plot" />
    </> }
  </>
}