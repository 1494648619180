import {Link} from "react-router-dom";
import {PlotLabel} from "components/PlotLabel";
import {plotSort, positionSort} from "helpers";
import {PlotPosition} from "components/PlotPosition";
import {PlotFragment} from "generated/graphql";
import {Column} from "components/Table/Column";
import {TFunction} from "i18next";

export const getBaseColumns = (t: TFunction): Column<PlotFragment>[] => {
  return [
    {
      value: a => a.site,
      key: 'country',
      filter: true
    },
    {
      value: a => a.program,
      key: 'program',
      filter: true
    },
    {
      value: a => a.code,
      key: 'code',
      render: a => <Link to={`/plots/${a.type.toString()[0]}-${a.code}`}><PlotLabel plot={a} /></Link>,
      sorter: (a,b) => plotSort(a,b)
    },
    {
      key: 'position',
      value: a => `${a.position?.locationNumber}-${a.position?.table}`,
      render: a => <PlotPosition plot={a} />,
      sorter: (a, b) => positionSort(a.position, b.position),
      filter: true
    },
    {
      key: 'status',
      value: a => a.dates.removed ? t('removed')
        : a.dates.done ? t('done')
          : a.dates.onHold ? t('on_hold')
            : t('running'),
      filter: true
    }
  ]
}