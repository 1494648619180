import {useMutation, useQuery} from "@apollo/client";
import {Button, Col, Form, Modal, Row, Select, Table} from "antd";
import {
  EventType,
  GetReservedPlotsDocument,
  GetSitesDocument,
  PlotWithCrossingsFatherFragment,
  UpdateSiteDocument,
  UserRole
} from "generated/graphql";
import {ColumnType} from "antd/es/table";
import {DateTime} from "luxon";
import {useState} from "react";
import {useAddEvent} from "hooks/useAddEvent";
import {PlotLabel} from "components/PlotLabel";
import {useTranslate} from "hooks/useTranslate";
import {useGetRole} from "hooks/useGetRole";
import {Column} from "components/Table/Column";
import {DataTable} from "components/Table/DataTable";

const { confirm } = Modal;
const sumCounts = (arr: { count: number }[]) => arr.reduce((c, a) => c + a.count, 0);

export const Reserved = () => {
  const { t } = useTranslate('reserved');
  const [siteId, setSiteId] = useState<number>(0);
  const { data, loading, refetch } = useQuery(GetReservedPlotsDocument,
    {
      variables: { siteId },
      skip: siteId === 0
    });

  const role = useGetRole();

  const sites = useQuery(GetSitesDocument);
  const siteOptions = sites.data?.sites.map(s => ({
    value: s.id,
    label: s.name
  }));
  const site = sites.data?.sites.filter(s => s.id === siteId)[0];
  const selectedDates = site?.activeDates ?? [];
  const [updateSite] = useMutation(UpdateSiteDocument);

  const getSelectedReservations = (a: PlotWithCrossingsFatherFragment) =>
    a.crossingsFather
      .flatMap(c => c.reservations.map(r => ({ position: c.mother?.position, ...r })))
      .filter(c => selectedDates.includes(c.date));

  const updateActiveDates = async (dates: string[]) => {
    await updateSite({
      variables: { id: siteId, activeDates: dates }
    });
  }

  const positionSource = data?.plots
    .flatMap(c => c.crossingsFather)
    .filter(c => c.reservations.filter(r => selectedDates.includes(r.date)).length > 0)
    .map(c => c.mother?.position);

  const posIds = Array.from(new Set(positionSource?.map(p => p?.id)));
  const positions = posIds.map(i => positionSource?.filter(p => p?.id === i)[0])
    .sort((p,q) => p && q ? p.locationNumber * 100 + p.table -q.locationNumber * 100 - q.table : 0);

  const columns: Column<PlotWithCrossingsFatherFragment>[] = [
    {
      key: 'code',
      value: t => t.code,
      render: t => <PlotLabel plot={t} />
    },
    {
      key: 'number',
      value: t => sumCounts(getSelectedReservations(t))
    },
    ...positions.map(p => ({
      key: `${p?.locationNumber}-${p?.table}`,
      header:  <span className={'reserved-column-header'}>{t('greenhouse')} {p?.locationNumber},<br/>{t('table')} {p?.table}</span>,
      value: (t: PlotWithCrossingsFatherFragment) => sumCounts(getSelectedReservations(t).filter(r => r.position?.id === p?.id))
    }))
  ];

  const reservations = data?.plots
    .flatMap(r => r.crossingsFather.flatMap(c => c.reservations));

  const dates = Array.from(new Set(reservations?.map(r => r.date as string)))
    .sort((a,b) => a.localeCompare(b))
    .map(a => ({ date: a }));

  const [addEvent] = useAddEvent();
  const unreserve = async (date: string) => {
    if (!reservations) return;
    const targets = reservations.filter(r => r.date === date);
    for (const target of targets) {
      await addEvent({
        variables: { crossingId: target.crossingId, reservationId: target.id, count: target.count, type: EventType.Unreserve }
      });
    }
    await refetch();
  }

  const dateColumns: ColumnType<{date: string}>[] = [
    {
      title: t('date'),
      key: 'date',
      render: (_, a) => DateTime.fromISO(a.date).toLocaleString(DateTime.DATE_HUGE)
    },
    {
      title: t('num_emasculated'),
      key: 'count',
      render: (_, a) => sumCounts(reservations?.filter(r => r.date === a.date) ?? [])
    },
    {
      title: '',
      key: 'action',
      render: (_, a) => <>{ role === UserRole.Admin && <Button type="link" onClick={() => confirm({
        title: t('close_reservations'),
        content: `${t('close_pre_date')} ${DateTime.fromISO(a.date).toLocaleString(DateTime.DATE_HUGE)} ${t('close_post_date')}?`,
        onOk: () => unreserve(a.date)
      })}>{t('close')}</Button> }</>
    }
  ]

  const summary = () => {
    return <>
      <Table.Summary.Row>
        <Table.Summary.Cell index={0}><i>{t('total')}</i></Table.Summary.Cell>
        <Table.Summary.Cell index={1} colSpan={2}>
          { data?.plots.reduce((s, a) => s + sumCounts(getSelectedReservations(a)), 0)}
        </Table.Summary.Cell>
      </Table.Summary.Row>
    </>
  }

  return <>
    <Row className="no-print">
      <Col span={3}>
        { sites.data && <div>
            <Form.Item label={t('location')}>
                <Select options={siteOptions} onChange={setSiteId} />
            </Form.Item>
        </div> }
      </Col>
    </Row>
    { loading && <div><i>{t('loading')}</i></div> }
    { data && <>


        <Row gutter={32} className="no-print">
            <Col span={10}>
                <h3>{t('emasculations')}</h3>
                <Table dataSource={dates}
                   columns={dateColumns}
                   rowSelection={{
                     type: "checkbox",
                     selectedRowKeys: site?.activeDates,
                     getCheckboxProps: r => ({ disabled: role !== UserRole.Admin }),
                     onChange: (_, vals) => updateActiveDates(vals.map(r => r.date))
                   }}
                   rowKey="date"
                   pagination={false}
              />
            </Col>
        </Row>
        <Row gutter={32}>
            <Col span={24}>
                <h3 style={{marginTop: 30}}>{t('pick_up_list')}</h3>
                <DataTable source={data.plots.filter(p => getSelectedReservations(p).length > 0)}
                           columns={columns}
                           translatePrefix="reserved"
                           exportPrefix={selectedDates
                             .map(d => DateTime.fromISO(d).toISODate({ format: 'basic' }))
                             .join("_") + "_"}
                           summary={summary}
                           extraExportHeader={['Selected dates: ', ...selectedDates.map(d => DateTime.fromISO(d).toISODate({ format: 'basic' }))]}
                />
            </Col>
        </Row>
    </>}

  </>
}