import {useMutation, useQuery} from "@apollo/client";
import {
  DeleteProgramDocument,
  GetProgramsDocument,
  ProgramFragment,
  UpdateProgramDocument,
  UserRole
} from "generated/graphql";
import {ColumnType} from "antd/es/table";
import {Button, Checkbox, Modal, Table} from "antd";
import {useState} from "react";
import {useTranslate} from "hooks/useTranslate";
import {useGetRole} from "hooks/useGetRole";
import {DateTime} from "luxon";

const { confirm } = Modal;

export const Programs = () => {
  const { data, loading, refetch } = useQuery(GetProgramsDocument);
  const [editProgram, setEditProgram] = useState<ProgramFragment | null>(null);
  const [priority, setPriority] = useState<number[]>([]);
  const { t } = useTranslate('programs');

  const [updateProgram] = useMutation(UpdateProgramDocument);
  const [deleteProgram] = useMutation(DeleteProgramDocument);

  const toggleArchived = async (program: ProgramFragment) => {
    await updateProgram({
      variables: { ...program, endDate: program.endDate ? null : DateTime.now().toISO() }
    });
    await refetch();
  }

  const columns: ColumnType<ProgramFragment>[] = [
    {
      title: t('code'),
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code)
    },
    {
      title: t('year'),
      dataIndex: 'year',
      key: 'year',
      sorter: (a, b) => a.year - b.year
    }
  ];
  const role = useGetRole();
  if (role === UserRole.Admin) {
    columns.push({
      title: t('priority_emasculation'),
      dataIndex: 'activeEmasculationPriority',
      key: 'activeEmasculationPriority',
      render: (_, a) => <>{ !a.endDate && <div>
        {a.activeEmasculationPriorities.length === 0 ? <i>{t('all')}</i> : a.activeEmasculationPriorities.join(', ')}
        <Button type="link" onClick={() => { setEditProgram(a); setPriority(a.activeEmasculationPriorities); }}>({t('edit')})</Button>
      </div> }</>
    });
    columns.push({
      key: 'delete',
      render: (_, a) => <>
        <Button type='link' onClick={async () => await toggleArchived(a)}>
          {t(a.endDate ? 'unarchive' : 'archive')}
        </Button>
        { !a.endDate && <Button type='link' onClick={() => confirm({
          title: t('remove_title'),
          content: t('remove_warning', { code: a.code }),
          onOk: async () => { await deleteProgram({ variables: { id: a.id }}); await refetch(); }
        })}>
          {t('remove')}
        </Button> }
      </>
    })
  }

  const editPriority = async () => {
    if (!editProgram) return;
    await updateProgram({
      variables: { id: editProgram.id, activeEmasculationPriorities: priority, endDate: editProgram.endDate }
    });
    setEditProgram(null);
  };

  return <>
    { loading && <div>{t('loading')}</div> }
    { data && <Table dataSource={data.programs} rowKey="id" sticky={{ offsetHeader: 64 }}
                     columns={columns} pagination={false}
                     style={{ maxWidth: 1200 }}
    /> }
    <Modal title={`Prioriteit aanpassen voor ${editProgram?.code}`}
           open={editProgram !== null}
           onOk={editPriority}
           onCancel={() => setEditProgram(null)}>
      <div>{t('choose_priorities')}:</div>
      { editProgram && <div>
        <Checkbox.Group options={editProgram.priorities}
                        value={priority}
                        onChange={v => setPriority(v as number[])} />
      </div> }
    </Modal>
  </>
}