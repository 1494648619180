import React, {PropsWithChildren} from "react";
import {MsalAuthenticationTemplate, MsalProvider} from "@azure/msal-react";
import {Configuration, InteractionType, PublicClientApplication} from "@azure/msal-browser";
import {GraphqlProvider} from "./GraphqlProvider";
import {clientId, tenantId} from "env";

const config: Configuration = {
  auth: {
    clientId,
    authority: `https://login.microsoft.com/${tenantId}`,
    redirectUri: '/'
  }
};

const pca = new PublicClientApplication(config);

export const AuthProvider = ({children}: PropsWithChildren<{}>) => {
  return <MsalProvider instance={pca}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} >
      <GraphqlProvider>
        {children}
      </GraphqlProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
};